<template>

	<div v-if="this.spinner.load" id="spinner">
		<svg id="svg-spinner" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><circle cx="24" cy="4" r="4" fill="#fff"/> <circle cx="12.19" cy="7.86" r="3.7" fill="#ffdfd3"/> <circle cx="5.02" cy="17.68" r="3.4" fill="#ffc9b3"/> <circle cx="5.02" cy="30.32" r="3.1" fill="#ffb596"/> <circle cx="12.19" cy="40.14" r="2.8" fill="#ffac8a"/> <circle cx="24" cy="44" r="2.5" fill="#ffa37e"/> <circle cx="35.81" cy="40.14" r="2.2" fill="#fb8e61"/> <circle cx="42.98" cy="30.32" r="1.9" fill="#ff8350"/> <circle cx="42.98" cy="17.68" r="1.6" fill="#fd763e"/> <circle cx="35.81" cy="7.86" r="1.3" fill="#ff6b2e"/></svg>
	</div>

	<HeaderTemplate />

	<section class="relative mx-auto mt-20 mb-20 w-full max-w-container sm:px-6 lg:px-10">
		<div class="container mx-auto">
			<div class="grid grid-cols-12">
				<div class="mb-6 col-span-8 padding-col">
					<h2 class="title">Finanziario</h2>
					<p class="valor-finanziario">R$ 120.260,58</p>
					<p class="subtitle-valor">Total gerado no mês 09/2023</p>
				</div>

				<div class="mb-6 col-span-12 padding-col">
					<h2 class="title">Use o Filtro</h2>
					<div class="grid grid-cols-5 gap-5">
						<div class="input-filtro">
							<Multiselect
			                    v-model="imobiliaria_option"
			                    placeholder="Selecione a imobiliária"
			                    mode="tags"
			                    :close-on-select="false"
			                    :searchable="true"
			                    :create-option="true"
                      			:options="imobiliarias"
                      			@change="filter()"
			                />
						</div>
						<div class="input-filtro">
							<Multiselect
			                    v-model="plano_option"
			                    placeholder="Selecione o Plano"
			                    mode="tags"
			                    :close-on-select="false"
			                    :searchable="true"
			                    :create-option="true"
                      			:options="planos"
			                />
						</div>
						<div class="input-filtro">
							<Multiselect
			                    v-model="status_option"
			                    placeholder="Selecione o Status"
			                    mode="tags"
			                    :close-on-select="false"
			                    :searchable="true"
			                    :create-option="true"
                      			:options="status"
			                />
						</div>
						<div class="input-filtro">
							<input type="email" id="email" v-model="codigo" placeholder="Código da Transação" :class="`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-default`" required>
						</div>
					</div>
				</div>

				<div class="mb-4 col-span-12 padding-col row-list">
					<div class="grid grid-cols-5 gap-5">
						<div class="dados-cad">
							<img class="w-12 h-12 rounded-full img-imob-valore" :src="`https://www.pannelloimmobiliare.it/images/cadastros/100.jpg`">
		                    <div class="text-base font-semibold imob-valore">Agenzia Cerri Im...</div>		                      
						</div>
						<div class="dados-valore">
							<p>11/09/2023</p>
							<span>Validade</span>
						</div>
						<div class="dados-valore">
							<p>R$ 66,00</p>
							<span>Valore</span>
						</div>
						<div class="dados-valore">
							<p>Mese</p>
							<span>Piano</span>
						</div>
						<div class="dados-valore">
							<button class="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-4 py-2.5 text-center button-default button-valor color-green">
								Pagamento Aprovado
							</button>
							<button class="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-4 py-2.5 text-center button-default button-more">
								+
							</button>
						</div>

					</div>
				</div>
				

				<div class="mb-4 col-span-12 padding-col row-list">
					<div class="grid grid-cols-5 gap-5">
						<div class="dados-cad">
							<img class="w-12 h-12 rounded-full img-imob-valore" :src="`https://www.pannelloimmobiliare.it/images/cadastros/100.jpg`">
		                    <div class="text-base font-semibold imob-valore">Agenzia Cerri Im...</div>		                      
						</div>
						<div class="dados-valore">
							<p>11/09/2023</p>
							<span>Validade</span>
						</div>
						<div class="dados-valore">
							<p>R$ 66,00</p>
							<span>Valore</span>
						</div>
						<div class="dados-valore">
							<p>Mese</p>
							<span>Piano</span>
						</div>
						<div class="dados-valore">
							<button class="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-4 py-2.5 text-center button-default button-valor color-green">
								Pagamento Aprovado
							</button>
							<button class="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-4 py-2.5 text-center button-default button-more">
								+
							</button>
						</div>

					</div>
				</div>
				

				<div class="mb-4 col-span-12 padding-col row-list">
					<div class="grid grid-cols-5 gap-5">
						<div class="dados-cad">
							<img class="w-12 h-12 rounded-full img-imob-valore" :src="`https://www.pannelloimmobiliare.it/images/cadastros/100.jpg`">
		                    <div class="text-base font-semibold imob-valore">Agenzia Cerri Im...</div>		                      
						</div>
						<div class="dados-valore">
							<p>11/09/2023</p>
							<span>Validade</span>
						</div>
						<div class="dados-valore">
							<p>R$ 66,00</p>
							<span>Valore</span>
						</div>
						<div class="dados-valore">
							<p>Mese</p>
							<span>Piano</span>
						</div>
						<div class="dados-valore">
							<button class="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-4 py-2.5 text-center button-default button-valor color-yellow">
								Pagamento Pendente
							</button>
							<button class="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-4 py-2.5 text-center button-default button-more">
								+
							</button>
						</div>

					</div>
				</div>
				

				<div class="mb-4 col-span-12 padding-col row-list">
					<div class="grid grid-cols-5 gap-5">
						<div class="dados-cad">
							<img class="w-12 h-12 rounded-full img-imob-valore" :src="`https://www.pannelloimmobiliare.it/images/cadastros/100.jpg`">
		                    <div class="text-base font-semibold imob-valore">Agenzia Cerri Im...</div>		                      
						</div>
						<div class="dados-valore">
							<p>11/09/2023</p>
							<span>Validade</span>
						</div>
						<div class="dados-valore">
							<p>R$ 66,00</p>
							<span>Valore</span>
						</div>
						<div class="dados-valore">
							<p>Mese</p>
							<span>Piano</span>
						</div>
						<div class="dados-valore">
							<button class="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-4 py-2.5 text-center button-default button-valor color-red">
								Pagamento Expirado
							</button>
							<button class="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-4 py-2.5 text-center button-default button-more">
								+
							</button>
						</div>

					</div>
				</div>
				
			</div>

		</div>
	</section>

	<Footer />
</template>

<script>
	import HeaderTemplate from '@/components/Header.vue';
	import Footer from '@/components/Footer.vue';
	import axios from 'axios';
    import Multiselect from '@vueform/multiselect';


	export default {
		name: 'Finanziario',
		components: {
			HeaderTemplate,
            Multiselect,
			Footer
		},

        data: function(){
			return {
				imobiliarias: [],
				imobiliaria_option: [],
				plano_option: [],
				status_option: [],
				planos: ['Mensale', "Anuale"],
				status: ["Aprovado", "Pendente", "Expirado"],
				codigo: null,
				spinner: {
					salva: false,
					load: true,
				}
			}
		},

        mounted(){
            this.getImobiliarias();
            this.filter();
        },

		methods : {
			getImobiliarias (){
				axios.get('/v1/imobiliarias-option')
                .then((response) => {
                    this.imobiliarias = response.data.imobiliarias;
                    this.spinner.load = false;
                })
                .catch(function (error) {
                	this.spinner.load = false;
                    console.log(error);
                });
			},
			filter (){
                this.spinner.load = true;
				axios.post('/v1/filtra-financeiro', {imobiliaria_option: this.imobiliaria_option, plano_option: this.plano_option, status_option: this.status_option, codigo: this.codigo})
                .then((response) => {
                    this.imobiliarias = response.data.imobiliarias;
                    this.spinner.load = false;
                })
                .catch(function (error) {
                	this.spinner.load = false;
                    console.log(error);
                });
			}
		}
	}


</script>
<style src="@vueform/multiselect/themes/default.css"></style>